import type { ReactNode } from 'react';
import React from 'react';

interface Props {
    title: string;
    text?: ReactNode;
}
const DetailsTitleWithText = ({ title, text }: Props) => (
    <li>
        <strong>
            {title}
        </strong>
        {' '}
        {text}
    </li>
);

export default DetailsTitleWithText;
