exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2L6TQ77J5xDo1m-dMawctj {\n    margin: 0;\n}", ""]);

// exports
exports.locals = {
	"root": "_2L6TQ77J5xDo1m-dMawctj"
};