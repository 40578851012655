import type { HTMLProps } from 'react';
import React from 'react';
import * as styles from '../css/bkk-section.css';
import analyzeVideo from '../assets/videos/analyze.mp4';
import analyzeBackground from '../assets/images/analyze_bg.png';
import bkkRefImage from '../assets/images/bkk/bkk-ref.png';
import ImageWithPath from '../components/ImageWithPath';
import TextWithImageSection from '../components/TextWithImageSection';
import { routes } from '../routes';
import Category from '../components/Category';
import Title from '../components/Title';
import Text from '../components/Text';
import SectionDetailsLink from '../components/SectionDetailsLink';

const BkkSection = (props: HTMLProps<HTMLDivElement>) => (
    <div {...props}>
        <TextWithImageSection
            text={(
                <>
                    <Category>Stories</Category>
                    <Title className={styles.title}>Centre for Budapest Transport (BKK)</Title>
                    <Text>
                        <p>
                            Our team has been involved in the creation of the Centre for Budapest Transport’s
                            state-of-the-art Traffic Management and Passenger Information System (FUTÁR) right from the
                            start in 2012.
                            <br />
                            Among the dozens of shared projects since, realCity designed and developed
                            BKK’s journey planning system for passengers and a corporate fact data management system for
                            timetable planning and customer service. The public trip planner website and mobile apps are
                            used by more than 1 000 000 unique users every month (you can see one day&apos;s usage on the
                            video to the right).
                        </p>
                    </Text>
                    <SectionDetailsLink navigateTo={routes.bkkStory}>
                        Details
                    </SectionDetailsLink>
                </>
            )}
            image={(
                <>
                    <div className="hidden-xs hidden-sm">
                        <ImageWithPath
                            variant="right"
                            video={analyzeVideo}
                            image={analyzeBackground}
                        />
                    </div>
                    <div className="hidden-md hidden-lg">
                        <ImageWithPath
                            variant="right"
                            image={bkkRefImage}
                        />
                    </div>
                </>
            )}
            alignment="image-right"
            polygonVariant="leftSided"
        />
    </div>
);

export default BkkSection;
