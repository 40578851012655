import React from 'react';
import { Col } from 'react-bootstrap';
import * as styles from '../css/download-link.css';
import { stopPropagation } from '../utils';
import type { DownloadLinkProps } from './DetailsPage';

const DownloadLink: React.FC<DownloadLinkProps> = ({ href, imageSrc }) => (
    <Col className={styles.root}>
        <a
            href={href}
            onClick={stopPropagation}
            target="_blank"
            rel="noreferrer"
        >
            <img className={styles.linkImage} src={imageSrc} />
        </a>
    </Col>
);

export default DownloadLink;
