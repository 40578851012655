import type { PropsWithChildren } from 'react';
import React from 'react';
import * as styles from '../css/section-details-link.css';
import type { DetailsLinkProps } from './DetailsLink';
import DetailsLink from './DetailsLink';

const SectionDetailsLink = (props: PropsWithChildren<DetailsLinkProps>) => (
    <DetailsLink className={styles.root} {...props} />
);

export default SectionDetailsLink;
