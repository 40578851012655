exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3e54_Qn1qp6NtPkEfZuKXj {\n    max-width: 700px;\n    padding: 0;\n    margin-top: 20px;\n}\n\n._3e54_Qn1qp6NtPkEfZuKXj > * {\n    width: 100%;\n    height: 100%;\n}\n\n.n42dyJnELyCgR_xaH_02p {\n    background:\n        linear-gradient(#EEEEEF, #EEEEEF) top / 100% 90.1%,\n        linear-gradient(to top left, transparent 50%, #EEEEEF 50%) bottom / 100% 10%;\n}\n\n._-6Wt5qSQ9q6ZMLytaeFhX {\n    background:\n        linear-gradient(#EEEEEF,#EEEEEF) top/100% 90.1%,\n        linear-gradient(to top right, transparent 50%, #EEEEEF 50%) bottom/100% 10%;\n}\n\n.n42dyJnELyCgR_xaH_02p, ._-6Wt5qSQ9q6ZMLytaeFhX {\n    background-repeat: no-repeat;\n}\n\n", ""]);

// exports
exports.locals = {
	"image": "_3e54_Qn1qp6NtPkEfZuKXj",
	"polygon-left-sided": "n42dyJnELyCgR_xaH_02p",
	"polygonLeftSided": "n42dyJnELyCgR_xaH_02p",
	"polygon-right-sided": "_-6Wt5qSQ9q6ZMLytaeFhX",
	"polygonRightSided": "_-6Wt5qSQ9q6ZMLytaeFhX"
};