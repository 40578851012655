exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".QS_oLo_OlUnYw98Xt9eFe {\n    box-sizing: content-box;\n    padding: 60px 0;\n    width: 100%;\n    position: relative;\n}\n\n.WSVSUWKzIHXttgNZoevHP {\n    float: right;\n    margin-right: 0;\n}\n\n.Z6xJILgdKrACxMKYMub2G {\n    float: left;\n    margin-left: 0;\n}\n\n._1Y_OwFW9gQn1B8YJ4yfzT5 {\n    padding-left: 70px;\n    padding-right: 5%;\n}\n\n.-k7fNo6pi5JChpkmHtALb {\n    float: right;\n    padding-left: 5%;\n    padding-right: 70px;\n}\n\n@media (max-width: 992px) {\n\n    .Z6xJILgdKrACxMKYMub2G, .WSVSUWKzIHXttgNZoevHP {\n        width: calc(100% - 30px);\n    }\n\n    ._1Y_OwFW9gQn1B8YJ4yfzT5, .-k7fNo6pi5JChpkmHtALb {\n        padding: 0 30px;\n        width: 100%;\n    }\n}\n", ""]);

// exports
exports.locals = {
	"contentContainer": "QS_oLo_OlUnYw98Xt9eFe",
	"first-right": "WSVSUWKzIHXttgNZoevHP",
	"firstRight": "WSVSUWKzIHXttgNZoevHP",
	"first-left": "Z6xJILgdKrACxMKYMub2G",
	"firstLeft": "Z6xJILgdKrACxMKYMub2G",
	"text-left": "_1Y_OwFW9gQn1B8YJ4yfzT5",
	"textLeft": "_1Y_OwFW9gQn1B8YJ4yfzT5",
	"text-right": "-k7fNo6pi5JChpkmHtALb",
	"textRight": "-k7fNo6pi5JChpkmHtALb"
};