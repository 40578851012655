exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3xNzSR2ojFGaffOu26zqsc {\n    display: inline-block;\n    margin-bottom: 29px;\n    margin-top: 20px;\n}", ""]);

// exports
exports.locals = {
	"root": "_3xNzSR2ojFGaffOu26zqsc"
};