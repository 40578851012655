exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._17vAsenA38W95pof3rrnly {\n    display: flex;\n    justify-content: center;\n    flex-wrap: wrap;\n    padding-top: 25px;\n}\n\n._1HidjO2VRsB0Mtm_8ucoqJ {\n    -webkit-filter: grayscale(100%);\n    filter: grayscale(100%);\n}", ""]);

// exports
exports.locals = {
	"root": "_17vAsenA38W95pof3rrnly",
	"grayscale": "_1HidjO2VRsB0Mtm_8ucoqJ"
};