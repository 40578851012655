exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2dtnFxbWEXYgQDJEXW432w {\n    height: 44px;\n    border-radius: 100px;\n    background-color: #2e5ea8;\n    font-size: 16px;\n    letter-spacing: 0.46px;\n    color: white;\n    border: none;\n    font-weight: 600;\n    cursor: pointer;\n    padding: 8px 58px 8px 58px;\n    line-height: 28px;\n    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12),\n    0px 2px 2px 0px rgba(0, 0, 0, 0.14),\n    0px 3px 1px -2px rgba(0, 0, 0, 0.2);\n    text-transform: uppercase;\n}\n\n._2dtnFxbWEXYgQDJEXW432w:hover {\n    background-color: #1D8ED6;\n}\n\n._2dtnFxbWEXYgQDJEXW432w:focus {\n    background-color: #2e5ea8;\n    outline: none;\n}\n\n._2dtnFxbWEXYgQDJEXW432w:focus, ._2dtnFxbWEXYgQDJEXW432w:hover {\n    text-decoration: none;\n    color: white;\n}", ""]);

// exports
exports.locals = {
	"root": "_2dtnFxbWEXYgQDJEXW432w"
};