import React from 'react';
import { Link } from 'react-router-dom';
import * as styles from '../css/header-item.css';
import { classNames } from '../utils';
import type { VariantType } from './LinkLayout';

export interface Props {
    targetId: string;
    variant?: VariantType;
}

const HeaderItem: React.FC<Props> = ({ targetId, children, variant }) => (
    <Link
        className={classNames(styles.root, variant === 'details' ? styles.detailsLink : styles.mainPageLink)}
        to={`/#${targetId}`}
    >
        {children}
    </Link>
);

export default HeaderItem;
