import type { PropsWithChildren } from 'react';
import React from 'react';
import { classNames } from '../utils';
import * as styles from '../css/product-card.css';
import DetailsLink from './DetailsLink';
import Text from './Text';

export type CardColor = 'blue' | 'green' | 'orange' | 'black';

export interface Props {
    cardTitle: string;
    icon: string;
    color: CardColor;
    navigateTo: string;
}

const ProductCard: React.FC<PropsWithChildren<Props>> = ({
    cardTitle,
    icon,
    color,
    children,
    navigateTo,
}) => (
    <div className={styles.card}>
        <div className={styles.contentContainer}>
            <div className={classNames(styles.headerContent, styles[color])}>
                <img className={styles.icon} src={icon} alt="CardIcon" />
                {cardTitle}
            </div>
            <Text className={styles.sectionContainer}>
                {children}
            </Text>
        </div>
        <div className={styles.buttonContainer}>
            <DetailsLink navigateTo={navigateTo}>Details</DetailsLink>
        </div>
    </div>
);

export default ProductCard;
