import { Col } from 'react-bootstrap';
import * as React from 'react';
import HeaderItem from './HeaderItem';
import { sectionIds } from '../containers/utils';
import * as styles from '../css/link-layout.css';

export type VariantType = 'details' | 'main';
interface Props {
    variant: VariantType;
}

const LinkLayout = ({ variant }: Props) => {
    const { productsId, storiesId, contactId, aboutId } = sectionIds;

    return (
        <div className={styles.linksContainer}>
            <Col xsHidden>
                <HeaderItem targetId={productsId} variant={variant}>
                    Products
                </HeaderItem>
            </Col>
            <Col xsHidden>
                <HeaderItem targetId={storiesId} variant={variant}>
                    Stories
                </HeaderItem>
            </Col>
            <Col xsHidden>
                <HeaderItem targetId={aboutId} variant={variant}>
                    About
                </HeaderItem>
            </Col>
            <Col xsHidden>
                <HeaderItem targetId={contactId} variant={variant}>
                    Contact
                </HeaderItem>
            </Col>
        </div>
    );
};

export default LinkLayout;
