exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._34sO7bHCSVTZVMk3H_kFdG {\n    display: flex;\n    gap: 40px;\n    padding-left: 40px;\n    padding-right: 40px;\n    flex-wrap: wrap;\n    margin-bottom: 60px;\n    justify-content: center;\n}\n\n.FH6NMJULoKZ4jW0HK3Q4I {\n    display: flex;\n    gap: 40px;\n    flex-wrap: wrap;\n    justify-content: center;\n}\n\n._1c8Fc8iY54447dXGwUHMZL {\n    aspect-ratio: 16/9;\n    border-radius: 0 30px 30px 0;\n    border: none;\n}\n\n", ""]);

// exports
exports.locals = {
	"cardContainer": "_34sO7bHCSVTZVMk3H_kFdG",
	"commonCardContent": "FH6NMJULoKZ4jW0HK3Q4I",
	"video": "_1c8Fc8iY54447dXGwUHMZL"
};