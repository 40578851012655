exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".XapNueVIRHXM8hu1NkSi {\n    columns: 2;\n    column-gap: 15%;\n}\n\n._3UdkeQUSTNy2VyW7bXwU3q li {\n    margin-bottom: 20px;\n}\n\nul {\n    margin: 0;\n}\n\n@media (max-width: 768px) {\n    .XapNueVIRHXM8hu1NkSi {\n        columns: 1;\n    }\n}\n", ""]);

// exports
exports.locals = {
	"multiple-column": "XapNueVIRHXM8hu1NkSi",
	"multipleColumn": "XapNueVIRHXM8hu1NkSi",
	"root": "_3UdkeQUSTNy2VyW7bXwU3q"
};