exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2b3yWAi0cwKO9O2N2SLC1u {\n    margin: 0 auto 50px;\n    max-width: 900px;\n    width: 100%;\n    height: 427px;\n}\n\n.carousel-inner {\n    height: 100%;\n}\n\n._1YACCEKcr-97K5THVRbuuY {\n    padding-top: 15px;\n    background:\n            linear-gradient(to top left, #EEEEEF 50%, transparent 50%) top/100% 10%,\n            linear-gradient(#EEEEEF, #EEEEEF) 100%/100% 80%,\n            linear-gradient(to top left, transparent 50%, #EEEEEF 50%) bottom/100% 10%;\n    background-repeat: no-repeat;\n}\n\n.carousel-inner > .item {\n    height: 100%;\n    align-items: center;\n}\n\n.carousel-inner > .active, .carousel-inner > .next, .carousel-inner > .previous {\n    display: flex;\n}\n\n.carousel-inner > ._3qqi6Ibr3vlX18n_FnJ9fQ > img {\n    max-height: 427px;\n    object-fit: contain;\n    border-radius: 32px;\n    border: solid 1px #E0E0E0;\n    outline: solid 1px #E0E0E0;\n    margin-left: auto;\n    margin-right: auto;\n    background-color: #ffffff;\n}\n\n.carousel-inner > ._1Ac50ae1d56076m2o4pVCy > img {\n    outline: unset;\n    border: unset;\n}\n\n._2b3yWAi0cwKO9O2N2SLC1u .carousel-control {\n    background-image: none;\n    text-shadow: 0 0 5px rgba(0,0,0,1), 0 0 3px rgba(0,0,0,1);\n}\n@media (max-width: 992px) {\n    ._2b3yWAi0cwKO9O2N2SLC1u {\n        max-width: 600px;\n    }\n}\n\n@media (max-width: 576px) {\n    ._2b3yWAi0cwKO9O2N2SLC1u {\n        max-width: 333px;\n    }\n}", ""]);

// exports
exports.locals = {
	"carousel-container": "_2b3yWAi0cwKO9O2N2SLC1u",
	"carouselContainer": "_2b3yWAi0cwKO9O2N2SLC1u",
	"carousel-polygon": "_1YACCEKcr-97K5THVRbuuY",
	"carouselPolygon": "_1YACCEKcr-97K5THVRbuuY",
	"carousel-item": "_3qqi6Ibr3vlX18n_FnJ9fQ",
	"carouselItem": "_3qqi6Ibr3vlX18n_FnJ9fQ",
	"no-carousel-frame": "_1Ac50ae1d56076m2o4pVCy",
	"noCarouselFrame": "_1Ac50ae1d56076m2o4pVCy"
};