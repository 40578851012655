exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".yG-ZNaRreMoqq1Wq-pHjH {\n    display: flex;\n    flex-direction: column;\n    max-width: 380px;\n    min-height: 240px;\n    border-radius: 24px;\n    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2), 0 6px 6px 0 rgba(0, 0, 0, 0.24);\n    background-color: #fff;\n}\n\n._1Xjwq5QlpRY2KmgChTtNQn {\n    padding: 24px 24px 0px;\n    flex: 1;\n}\n\n._3rqWqestCXZedHrVnQzCz3 {\n    font-size: 40px;\n    font-weight: 900;\n}\n\n.mnNb_oVlP-xw0rBiUV4rI {\n    padding-top: 32px;\n    padding-bottom: 32px\n}\n\n._3nxTBjuMAyATrKSG8Yxefo {\n    height: 1.4em;\n    margin-right: 0.375em;\n}\n\n.uw-E1oBHeJCZ4xA8spktY {\n    display: flex;\n    -webkit-box-align: center;\n    align-items: center;\n    padding: 0 20px 20px;\n}\n\n._1V3TAi4UG21kZGPiuVnENR {\n    color: #2E5EA8;\n}\n\n._2jclJUoA8wazspK9citUUh {\n    color: #008C72;\n}\n\n._2DW3NK_MZR4975fUCpIMwv {\n    color: #F9AB13;\n}\n\n._3n7rXkQIPbvSQ5-fGqPTM1 {\n    color: black;\n}\n\n@media (max-width: 430px) {\n    .yG-ZNaRreMoqq1Wq-pHjH {\n        margin-left: 18px;\n        margin-right: 18px;\n    }\n}\n\n@media (max-width: 320px) {\n    ._3rqWqestCXZedHrVnQzCz3 {\n        font-size: 30px;\n    }\n}\n\n@media (max-width: 190px) {\n    ._3rqWqestCXZedHrVnQzCz3 {\n        font-size: 20px;\n    }\n}", ""]);

// exports
exports.locals = {
	"card": "yG-ZNaRreMoqq1Wq-pHjH",
	"contentContainer": "_1Xjwq5QlpRY2KmgChTtNQn",
	"headerContent": "_3rqWqestCXZedHrVnQzCz3",
	"sectionContainer": "mnNb_oVlP-xw0rBiUV4rI",
	"icon": "_3nxTBjuMAyATrKSG8Yxefo",
	"buttonContainer": "uw-E1oBHeJCZ4xA8spktY",
	"blue": "_1V3TAi4UG21kZGPiuVnENR",
	"green": "_2jclJUoA8wazspK9citUUh",
	"orange": "_2DW3NK_MZR4975fUCpIMwv",
	"black": "_3n7rXkQIPbvSQ5-fGqPTM1"
};