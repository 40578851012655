import React from 'react';
import * as styles from '../css/details-section.css';
import { classNames } from '../utils';

interface Props {
    children: React.ReactNode;
    className?: string;
}

const DetailsSection = ({ children, className }: Props) => (
    <div className={classNames(styles.root, className)}>
        {children}
    </div>
);

export default DetailsSection;
