import React from 'react';
import { Col } from 'react-bootstrap';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import HeaderLogoAndLinks from './HeaderLogoAndLinks';
import Header from './Header';

export interface DownloadLinkProps {
    href: string;
    imageSrc: string;
}

const DetailsPage: React.FC = ({ children }) => (
    <>
        <Header>
            <HeaderLogoAndLinks variant="details" />
        </Header>
        <Col xs={12}>
            {children}
        </Col>
        <ScrollRestoration />
        <Outlet />
    </>
);

export default DetailsPage;
