/* eslint-disable max-len */
import type { HTMLProps } from 'react';
import React from 'react';
import developerImage from '../assets/images/developer.jpg';
import ImageWithPath from '../components/ImageWithPath';
import TextWithImageSection from '../components/TextWithImageSection';
import Title from '../components/Title';
import Category from '../components/Category';
import Text from '../components/Text';

const About = (props: HTMLProps<HTMLDivElement>) => (
    <div {...props}>
        <TextWithImageSection
            text={(
                <>
                    <Category>About</Category>
                    <Title>Real solutions with simplicity</Title>
                    <Text>
                        <p>
                            realCity is an agile team of transportation geeks, developers and IT experts, headquartered in Budapest. Our group has been doing transportation IT projects for 11 years.
                        </p>
                        <p>
                            Our passion is to provide rapid, smart and efficient
                            transport solutions to our partners - real solutions with simplicity.
                        </p>
                    </Text>
                </>
            )}
            image={(
                <ImageWithPath
                    variant="right"
                    image={developerImage}
                />
            )}
            alignment="image-right"
            polygonVariant="leftSided"
        />
    </div>
);

export default About;
