import React from 'react';
import ProductCard from '../components/ProductCard';
import replay from '../assets/images/products/replay.svg';
import { routes } from '../routes';

const ReplayCard = () => (
    <ProductCard
        cardTitle="replay"
        navigateTo={routes.replayProduct}
        color="green"
        icon={replay}
    >
        Travel back in time and compare planned and realized trips, see what has happened and find the reasons for
        problems with our map-based traffic analysis and historical data visualisation tool.
    </ProductCard>
);

export default ReplayCard;
