exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".gx_ObBWyGY2uNgldc5-EM {\n    display: flex;\n    align-items: flex-start;\n}\n\n.b3MqIsfUlVYHYXRHl3lGm {\n    width: 80px;\n    height: 44px;\n    margin-right: 30px;\n}\n\n@media (max-width: 768px) {\n\n    .b3MqIsfUlVYHYXRHl3lGm {\n        margin-top: 30px;\n    }\n}", ""]);

// exports
exports.locals = {
	"logo-and-link-container": "gx_ObBWyGY2uNgldc5-EM",
	"logoAndLinkContainer": "gx_ObBWyGY2uNgldc5-EM",
	"logo": "b3MqIsfUlVYHYXRHl3lGm"
};