import type { PropsWithChildren } from 'react';
import React from 'react';
import * as styles from '../css/title.css';
import { classNames } from '../utils';

interface Props {
    children: React.ReactNode;
    className?: string;
}

const Title = ({ children, className }: PropsWithChildren<Props>) => (
    <div className={classNames(styles.root, className)}>
        {children}
    </div>
);

export default Title;
