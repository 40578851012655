exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@media (max-width: 576px) {\n    .PG0h3tfGk4DHz_yavgaMZ {\n        width: 100%;\n    }\n}", ""]);

// exports
exports.locals = {
	"ktiImage": "PG0h3tfGk4DHz_yavgaMZ"
};