exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Xi1vPaLPNapXAYu2Fgc2a {\n    padding: 0 50px;\n    width: 100%;\n    display: flex;\n    justify-content: center;\n}\n\n@media (max-width: 768px) {\n    .Xi1vPaLPNapXAYu2Fgc2a {\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n    }\n}\n", ""]);

// exports
exports.locals = {
	"root": "Xi1vPaLPNapXAYu2Fgc2a"
};