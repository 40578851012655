exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".elGnN9YL6k1gcNEgpAiSm {\n    padding-left:120px;\n    padding-right: 120px;\n}\n\n@media (max-width: 768px) {\n    .elGnN9YL6k1gcNEgpAiSm {\n        padding-left: 60px;\n        padding-right: 60px;\n    }\n}", ""]);

// exports
exports.locals = {
	"root": "elGnN9YL6k1gcNEgpAiSm"
};