var escape = require("../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".lOlO8PMr-6BKmLwhp9pP0 {\n    position: relative;\n    width: 100%;\n    height: 700px;\n    overflow: hidden;\n}\n\n._1K973hbNK5bVVqVPn0omXR {\n    font-size: 64.6px;\n    font-weight: 600;\n    color: white;\n    padding-top: 60px;\n    text-shadow: 2px 2px #000000;\n}\n\n.RNSfY1icHBO74oNJvohAh {\n    object-fit: cover;\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    z-index: -100;\n}\n\n@media (max-width: 992px) {\n    .lOlO8PMr-6BKmLwhp9pP0 {\n        background-image: url(" + escape(require("../../src/assets/images/mobileBackground.png")) + ");\n        background-size: cover;\n        background-position: center;\n    }\n\n    .RNSfY1icHBO74oNJvohAh {\n        display: none;\n    }\n}\n\n@media (max-width: 768px) {\n    ._1K973hbNK5bVVqVPn0omXR {\n        font-size: 28px;\n        line-height: 49px;\n    }\n\n    .lOlO8PMr-6BKmLwhp9pP0 {\n        height: 500px;\n    }\n\n    ._1wHhTGI8QsvnqeQaQh9ktB {\n        padding-top: 0;\n        padding-left: 15px;\n    }\n}\n\n", ""]);

// exports
exports.locals = {
	"container": "lOlO8PMr-6BKmLwhp9pP0",
	"bannerText": "_1K973hbNK5bVVqVPn0omXR",
	"bgVideo": "RNSfY1icHBO74oNJvohAh",
	"contentContainer": "_1wHhTGI8QsvnqeQaQh9ktB"
};