import React from 'react';
import { Grid } from 'react-bootstrap';
import * as styles from '../css/header.css';

const Header: React.FC = ({ children }) => (
    <Grid className={styles.root}>
        {children}
    </Grid>
);

export default Header;
