import type { PropsWithChildren } from 'react';
import React from 'react';
import { classNames } from '../utils';
import * as styles from '../css/text.css';

interface Props {
    children: React.ReactNode;
    className?: string;
}
const Text = ({ children, className }: PropsWithChildren<Props>) => (
    <div className={classNames(styles.root, className)}>
        {children}
    </div>
);

export default Text;
