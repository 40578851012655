import type { ReactNode } from 'react';
import React from 'react';
import { Col, Grid } from 'react-bootstrap';
import * as styles from '../css/two-column-section.css';
import { classNames } from '../utils';

export type ContentAlignment = 'first-left' | 'first-right';

export interface Props {
    first: ReactNode;
    second: ReactNode;
    alignment?: ContentAlignment;
    className?: string;
    secondClassName?: string;
}

const TwoColumnSection: React.FC<Props> = ({ first, second, alignment = 'first-right', className, secondClassName }) => {
    const secondClass = alignment === 'first-left' ? styles.firstLeft : styles.firstRight;
    const firstClass = alignment === 'first-left' ? styles.textRight : styles.textLeft;

    return (
        <Grid className={classNames(styles.contentContainer, className)}>
            <Col xs={12} md={6} className={firstClass}>
                {first}
            </Col>
            <Col xs={12} md={6} className={classNames(secondClass, secondClassName)}>
                {second}
            </Col>
        </Grid>
    );
};

export default TwoColumnSection;
