import React from 'react';
import { Carousel, CarouselItem } from 'react-bootstrap';
import * as styles from '../css/smart-carousel.css';
import { stopPropagation } from '../utils';

export interface Props {
    images: string[];
}

const SmartCarousel: React.FC<Props> = ({ images }) => (
    <div className={styles.carouselPolygon}>
        <Carousel
            onClick={stopPropagation}
            className={styles.carouselContainer}
        >
            {images.map(image => (
                <CarouselItem key={image} className={styles.carouselItem}>
                    <img src={image} />
                </CarouselItem>
            ))}
        </Carousel>
    </div>
);

export default SmartCarousel;
