exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@media (max-width: 992px) {\n    ._3fv8fbhmGRRbsytlSFnOMj {\n        white-space: normal;\n    }\n}", ""]);

// exports
exports.locals = {
	"title": "_3fv8fbhmGRRbsytlSFnOMj"
};