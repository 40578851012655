import React from 'react';
import * as styles from '../css/download-links.css';

const DownloadLinks: React.FC = ({ children }) => (
    <div className={styles.root}>
        {children}
    </div>
);

export default DownloadLinks;
