import React from 'react';
import ProductCard from '../components/ProductCard';
import arrow from '../assets/images/products/arrow.svg';
import { routes } from '../routes';

const DriveCard = () => (
    <ProductCard
        cardTitle="drive"
        navigateTo={routes.driveProduct}
        color="black"
        icon={arrow}
    >
        Android-based state-of-the-art onboard unit which can be used to easily integrate public transport vehicles into
        an online real-time traffic management and passenger information system.
    </ProductCard>
);

export default DriveCard;
