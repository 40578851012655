exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1VSpNq0TbeukwTtqYHY_0d {\n    width: 200px;\n    height: 60px;\n    background-size: contain;\n    background-position: center;\n    background-repeat: no-repeat;\n}\n\n._2eM5T5G4e5bqpN_5rymtl3 {\n    margin: 20px;\n}\n\n._1oVeyEWPH2ouKCKExtaZKf {\n    margin: 20px 40px\n}", ""]);

// exports
exports.locals = {
	"root": "_1VSpNq0TbeukwTtqYHY_0d",
	"small-gap": "_2eM5T5G4e5bqpN_5rymtl3",
	"smallGap": "_2eM5T5G4e5bqpN_5rymtl3",
	"large-gap": "_1oVeyEWPH2ouKCKExtaZKf",
	"largeGap": "_1oVeyEWPH2ouKCKExtaZKf"
};