import React from 'react';
import { classNames } from '../utils';
import * as styles from '../css/powering-image.css';

interface Props {
    className?: string;
    gapSize?: 'small' | 'large';
}

const PoweringImage = ({ className, gapSize = 'small' }: Props) => (
    <div className={classNames(styles.root, className, gapSize === 'small' ? styles.smallGap : styles.largeGap)} />
);

export default PoweringImage;
