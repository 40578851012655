import * as React from 'react';
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as styles from '../css/header-logo-and-links.css';
import type { VariantType } from './LinkLayout';
import LinkLayout from './LinkLayout';
import { routes } from '../routes';
import coloredLogo from '../assets/images/realCitylogoColor.png';
import whiteLogo from '../assets/images/realCitylogo.png';

interface Props {
    variant: VariantType;
}

const HeaderLogoAndLinks = ({ variant }: Props) => (
    <div className={styles.logoAndLinkContainer}>
        <Col>
            <Link to={routes.main}>
                <img
                    src={variant === 'details' ? coloredLogo : whiteLogo}
                    alt="logo"
                    className={styles.logo}
                />
            </Link>
        </Col>
        <LinkLayout variant={variant} />
    </div>
);

export default HeaderLogoAndLinks;
