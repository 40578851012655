import React from 'react';
import ProductCard from '../components/ProductCard';
import hail from '../assets/images/products/hail.svg';
import { routes } from '../routes';

const RideCard = () => (
    <ProductCard
        cardTitle="ride"
        navigateTo={routes.rideProduct}
        color="orange"
        icon={hail}
    >
        Mobile and web-based passenger information and multi-modal trip planning apps customized to local requirements
        with easy integration possibilities.
    </ProductCard>
);

export default RideCard;
