exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2v7-Mpa2iruAGfNIa2h-wv {\n    font-size: 14px;\n    font-weight: 600;\n    letter-spacing: 1px;\n    text-transform: uppercase;\n    text-align: left;\n}\n\n.IztyrNpQXJDVNZubzBXuH {\n    text-align: center;\n}", ""]);

// exports
exports.locals = {
	"root": "_2v7-Mpa2iruAGfNIa2h-wv",
	"alignTextCenter": "IztyrNpQXJDVNZubzBXuH"
};