import type { PropsWithChildren, ReactNode } from 'react';
import React from 'react';
import * as styles from '../css/powering-image-container.css';
import { classNames } from '../utils';

interface Props {
    children: ReactNode;
    isGrayscale?: boolean;
    className?: string;
}

const PoweringImageContainer = ({ children, className, isGrayscale }: PropsWithChildren<Props>) => (
    <div className={classNames(styles.root, className, isGrayscale && styles.grayscale)}>
        {children}
    </div>
);

export default PoweringImageContainer;
