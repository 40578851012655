/* eslint-disable max-len */
import * as React from 'react';
import * as styles from '../css/kti-section.css';
import ktiRefImage from '../assets/images/kti/kti-ref.png';
import ImageWithPath from '../components/ImageWithPath';
import TextWithImageSection from '../components/TextWithImageSection';
import { routes } from '../routes';
import Title from '../components/Title';
import Category from '../components/Category';
import Text from '../components/Text';
import SectionDetailsLink from '../components/SectionDetailsLink';

const KtiSection: React.FC = () => (
    <TextWithImageSection
        text={(
            <>
                <Category>Stories</Category>
                <Title>UTAS.HU</Title>
                <Text>
                    <p>
                        utas.hu is a nationwide, real-time public transport information system for Hungary.
                        It consists of a responsive webpage and two mobile apps.
                        <br />
                        Featuring a modern and intuitive user interface, our multimodal journey planner engine uses real-time data of countrywide and local public transport providers (trains, coach service, local buses etc.) to offer passengers door-to-door plans to reach their destination across the country.
                    </p>
                </Text>
                <SectionDetailsLink navigateTo={routes.ktiStory}>
                    Details
                </SectionDetailsLink>
            </>
        )}
        image={(
            <ImageWithPath
                variant="right"
                image={ktiRefImage}
            />
        )}
        alignment="image-right"
        polygonVariant="rightSided"
        imageClassName={styles.ktiImage}
    />
);

export default KtiSection;
