import React from 'react';
import DetailsHeaderText from '../components/DetailsHeaderText';
import SmartCarousel from '../components/SmartCarousel';
import rideTransparentImage1 from '../assets/images/ride/ride_transparent1.png';
import rideTransparentImage2 from '../assets/images/ride/ride_transparent2.png';
import rideImage1 from '../assets/images/ride/ride_1.png';
import rideImage2 from '../assets/images/ride/ride_2.png';
import DetailsText from '../components/DetailsText';
import DetailsList from '../components/DetailsList';

const RideCardDetailsContent = () => (
    <>
        <DetailsHeaderText
            category="Products"
            title="realCity | ride"
            subtitle="Mobile and web based passenger information and trip planning solution "
        />
        <DetailsText>
            <DetailsList>
                <li>Native mobile apps for Android and iOS</li>
                <li>Messenger chatbot and responsive web client</li>
                <li>Real-time status of public transport vehicles and the state of bike sharing stations</li>
                <li>Multi-modal trip planning with vehicle tracking</li>
                <li>Locations of ticket vending machines</li>
                <li>Easy integration of new data sources</li>
            </DetailsList>
        </DetailsText>
        <SmartCarousel
            images={[
                rideTransparentImage1,
                rideTransparentImage2,
                rideImage1,
                rideImage2,
            ]}
        />
    </>
);

export default RideCardDetailsContent;
