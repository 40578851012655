exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".pBaTPWoSBkxMYFArhjPsQ {\n    padding: 0 50px 10px;\n}\n\n.iOgmJIzG6OSr7XtRO6fLp {\n    height: 50px;\n}\n\n@media (max-width: 768px) {\n    .pBaTPWoSBkxMYFArhjPsQ {\n        padding: 10px;\n    }\n}", ""]);

// exports
exports.locals = {
	"root": "pBaTPWoSBkxMYFArhjPsQ",
	"linkImage": "iOgmJIzG6OSr7XtRO6fLp"
};