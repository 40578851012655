import { useState } from 'react';

let nextId = 0;

export const useUniqueId = () => useState(() => nextId++)[0].toString();

export const stopPropagation = (e: React.MouseEvent<any, any>) => {
    e.stopPropagation();
};

export function classNames(...classes: any[]): string {
    return classes.filter(Boolean).join(' ');
}
