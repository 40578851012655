import type { PropsWithChildren } from 'react';
import React from 'react';

interface Props {
    children: React.ReactNode;
}

const ImportantText = ({ children }: PropsWithChildren<Props>) => (
    <strong>
        {children}
    </strong>
);

export default ImportantText;
