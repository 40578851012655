exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1WcAkdV1J2ftNNP1BI0mlr {\n    width: 80px;\n    height: 44px;\n}\n\n._2yHQjhkOUqDxusnNd50U_n {\n    text-transform: uppercase;\n}\n\n._11X_mcdtuK2fKEFnd-kEYv {\n    max-width: 700px;\n    min-width: 400px;\n}\n\n._1oUlz-i1CmR7FimcfXhj1l {\n    display: flex;\n    gap: 16px;\n}\n\n.CRpCEznNiRlmvAMxsBC-p {\n    color: #2e5ea8;\n}\n\n.CRpCEznNiRlmvAMxsBC-p:hover {\n    color: #1D8ED6;\n}\n\n@media (max-width: 992px) {\n    ._1WcAkdV1J2ftNNP1BI0mlr {\n        margin-bottom: 30px;\n    }\n}\n", ""]);

// exports
exports.locals = {
	"logo": "_1WcAkdV1J2ftNNP1BI0mlr",
	"title": "_2yHQjhkOUqDxusnNd50U_n",
	"sectionTitleContainer": "_11X_mcdtuK2fKEFnd-kEYv",
	"socialLinkContainer": "_1oUlz-i1CmR7FimcfXhj1l",
	"social-link": "CRpCEznNiRlmvAMxsBC-p",
	"socialLink": "CRpCEznNiRlmvAMxsBC-p"
};