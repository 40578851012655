import React from 'react';
import DetailsHeaderText from '../components/DetailsHeaderText';
import SmartCarousel from '../components/SmartCarousel';
import replayImage1 from '../assets/images/replay/replay_1.png';
import replayImage2 from '../assets/images/replay/replay_2.png';
import DetailsText from '../components/DetailsText';
import DetailsList from '../components/DetailsList';

const ReplayDetailsContent = () => (
    <>
        <DetailsHeaderText
            category="Products"
            title="realCity | replay"
            subtitle="Traffic analysis and visualisation on historical data"
        />
        <DetailsText>
            <DetailsList>
                <li>Compare planned and realized trips</li>
                <li>Find the reasons behind problems</li>
                <li>Easily extend the tool with your own data sources</li>
                <li>Simple to deploy</li>
                <li>Numerous visualization tools and statistical graphs</li>
                <li>Detailed reports</li>
            </DetailsList>
        </DetailsText>
        <SmartCarousel
            images={[
                replayImage1,
                replayImage2,
            ]}
        />
    </>
);

export default ReplayDetailsContent;
