import type { PropsWithChildren } from 'react';
import React from 'react';
import { classNames } from '../utils';
import * as styles from '../css/details-list.css';

interface DetailsListProps {
    children: React.ReactNode;
    singleColumn?: boolean;
}
const DetailsList = ({ children, singleColumn }: PropsWithChildren<DetailsListProps>) => (
    <ul className={classNames(!singleColumn && styles.multipleColumn, styles.root)}>
        {children}
    </ul>
);

export default DetailsList;
