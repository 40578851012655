import React from 'react';
import { Link } from 'react-router-dom';
import * as styles from '../css/details-header-text.css';
import DetailsCategory from './DetailsCategory';
import DetailsSection from './DetailsSection';
import Title from './Title';

interface Props {
    category: 'Products' | 'Stories';
    title: string;
    subtitle?: string;
}

const DetailsHeaderText = ({ title, subtitle, category }: Props) => (
    <DetailsSection className={styles.root}>
        <DetailsCategory>
            {category}
        </DetailsCategory>
        <div className={styles.titleWithExitIconContainer}>
            <Link to="/">
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.icon}>
                    {/* eslint-disable-next-line max-len */}
                    <path d="M36.7752 16.775C35.8002 15.8 34.2252 15.8 33.2502 16.775L21.7752 28.25C20.8002 29.225 20.8002 30.8 21.7752 31.775L33.2502 43.25C34.2252 44.225 35.8002 44.225 36.7752 43.25C37.7502 42.275 37.7502 40.7 36.7752 39.725L27.0752 30L36.7752 20.3C37.7502 19.325 37.7252 17.725 36.7752 16.775Z" fill="#323232" />
                </svg>
            </Link>
            <Title>
                {title}
            </Title>
        </div>
        <div className={styles.subtitle}>
            {subtitle}
        </div>
    </DetailsSection>
);

export default DetailsHeaderText;
