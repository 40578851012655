exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1eDZmQfYMHz6zPyPgkUU0Y {\n    height: 50px;\n    font-size: 17px;\n    font-weight: 600;\n    cursor: pointer;\n    text-transform: uppercase;\n    line-height: 50px;\n    text-align: center;\n    margin: 0 30px;\n}\n\na:hover, a:focus {\n    outline: unset;\n    background-color: unset;\n}\n\n._3gV5gJLp8R-f-Edy4xgxV3 {\n    text-shadow: 1.25px 1.25px black;\n    color: #ffffff;\n}\n\n._3gV5gJLp8R-f-Edy4xgxV3:focus, ._3gV5gJLp8R-f-Edy4xgxV3:hover, ._3gV5gJLp8R-f-Edy4xgxV3:visited, a:focus {\n    color: #ffffff;\n    text-decoration: none;\n}\n\n._1n_839H-mgBz6jd18WlpeN {\n    color: black;\n}\n\n._1n_839H-mgBz6jd18WlpeN:hover, ._1n_839H-mgBz6jd18WlpeN:focus {\n    color: black;\n}\n\n._1n_839H-mgBz6jd18WlpeN:hover {\n    text-decoration: underline;\n}\n\n", ""]);

// exports
exports.locals = {
	"root": "_1eDZmQfYMHz6zPyPgkUU0Y",
	"main-page-link": "_3gV5gJLp8R-f-Edy4xgxV3",
	"mainPageLink": "_3gV5gJLp8R-f-Edy4xgxV3",
	"details-link": "_1n_839H-mgBz6jd18WlpeN",
	"detailsLink": "_1n_839H-mgBz6jd18WlpeN"
};