import * as React from 'react';
import DetailsHeaderText from '../components/DetailsHeaderText';
import SmartCarousel from '../components/SmartCarousel';
import ktiImage1 from '../assets/images/kti/kti-1.png';
import ktiImage2 from '../assets/images/kti/kti-2.png';
import ktiImage3 from '../assets/images/kti/kti-3.png';
import ktiTransparentImage1 from '../assets/images/kti/kti-transparent1.png';
import appStoreImage from '../assets/images/app-store-badge.png';
import googlePlayImage from '../assets/images/google-play-badge.png';
import webSiteImage from '../assets/images/kti/kti-badge.png';
import DetailsText from '../components/DetailsText';
import DownloadLink from '../components/DownloadLink';
import DownloadLinks from '../components/DownloadLinks';

const KtiDetailsContent = () => (
    <>
        <DetailsHeaderText
            category="Stories"
            title="UTAS.HU"
        />
        <DetailsText>
            <p>
                The development of the system was challenging due to the heterogeneous information systems
                of the different providers, each offering different, often non-standard interfaces.
                The main task was to aggregate the existing bus, train and local transit data sources and
                develop a complete information system with planning as a Proof of Concept of this
                aggregation.
            </p>
            <p>
                As a second step we upgraded the performance of our already existing trip-planning core
                module to be able to process and handle the increased amount of data and the calculating
                capacity to be able to provide door-to-door journeys in a matter of seconds for a whole
                country.
            </p>
            <p>
                Finally our developers redesigned the critical part of the trip-planning user interface.
            </p>
            <p>
                The utas.hu web and mobile apps are based on our existing off the shelf ITS software
                solutions with a hint of upgrade and magic.
            </p>
            <div>
                <p>The current system contains data of:</p>
                <ul>
                    <li>Volánbusz (nationwide coach service and local transit provider for more than 30 cities)</li>
                    <li>MÁV-Start (national railway company)</li>
                    <li>MÁV-HÉV (suburban train network around Budapest)</li>
                    <li>BKK (Centre for Budapest Transport)</li>
                    <li>Blaguss Agora Hungary (public transport provider for the city of Szombathely)</li>
                    <li>… and we are continuously extending to new providers.</li>
                </ul>
            </div>
        </DetailsText>
        <SmartCarousel
            images={[
                ktiImage1,
                ktiImage2,
                ktiImage3,
                ktiTransparentImage1,
            ]}
        />
        <DownloadLinks>
            <DownloadLink href="https://apps.apple.com/kh/app/utas-hu/id1471518870" imageSrc={appStoreImage} />
            <DownloadLink href="https://play.google.com/store/apps/details?id=io.realcity.mobile.kti" imageSrc={googlePlayImage} />
            <DownloadLink href="https://utas.hu/" imageSrc={webSiteImage} />
        </DownloadLinks>
    </>
);

export default KtiDetailsContent;
