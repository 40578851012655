import type { ReactNode } from 'react';
import React from 'react';
import TwoColumnSection from './TwoColumnSection';
import * as styles from '../css/text-with-image-section.css';
import { classNames } from '../utils';

type TextWithImageAlignment = 'image-left' | 'image-right';

export interface Props {
    text: ReactNode;
    image: ReactNode;
    alignment: TextWithImageAlignment;
    polygonVariant?: 'leftSided' | 'rightSided';
    imageClassName?: string;
}

const TextWithImageSection: React.FC<Props> = ({ text, image, alignment, polygonVariant, imageClassName }) => {
    const sectionAlignment = alignment === 'image-left' ? 'first-left' : 'first-right';
    return (
        <TwoColumnSection
            first={text}
            second={image}
            className={classNames(
                polygonVariant === 'leftSided' && styles.polygonLeftSided,
                polygonVariant === 'rightSided' && styles.polygonRightSided,
            )}
            alignment={sectionAlignment}
            secondClassName={classNames(styles.image, imageClassName)}
        />
    );
};

export default TextWithImageSection;
