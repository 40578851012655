import type { HTMLProps } from 'react';
import React from 'react';
import { Facebook, Instagram, Linkedin, PinMapFill } from 'react-bootstrap-icons';
import * as styles from '../css/contact.css';
import logo from '../assets/images/realCitylogoColor.png';
import TwoColumnSection from '../components/TwoColumnSection';
import Title from '../components/Title';
import Category from '../components/Category';
import Text from '../components/Text';

const Contact = (props: HTMLProps<HTMLDivElement>) => (
    <div {...props}>
        <TwoColumnSection
            first={(
                <>
                    <Category>Contact</Category>
                    <Title className={styles.sectionTitleContainer}>
                        Ready to bring your public transit system to the next level? Contact us!
                    </Title>
                </>
            )}
            second={(
                <Text>
                    <img src={logo} className={styles.logo} />
                    <p>1095 Budapest, Hungary</p>
                    <p>Soroksári út 32-34. Building E, 1st floor</p>
                    <p>
                        <a href="mailto:realcity@realcity.io">realcity@realcity.io</a>
                    </p>
                    <p>© 2024 realCity ITS Kft.</p>
                    <p>
                        <a href="/privacy_policy.pdf">Privacy Policy</a>
                    </p>
                    <div className={styles.socialLinkContainer}>
                        <a className={styles.socialLink} href="https://www.facebook.com/realcityits" aria-label="Facebook page">
                            <Facebook size={32} />
                        </a>
                        <a className={styles.socialLink} href="https://www.instagram.com/realcity_its/" aria-label="Instagram page">
                            <Instagram size={32} />
                        </a>
                        <a className={styles.socialLink} href="https://www.linkedin.com/company/realcity" aria-label="Linkedin Page">
                            <Linkedin size={32} />
                        </a>
                        <a
                            className={styles.socialLink}
                            // eslint-disable-next-line max-len
                            href="https://www.google.com/maps/place/realCity+ITS+Kft./@47.4743256,19.0693546,17z/data=!3m1!4b1!4m6!3m5!1s0x4741dd01f170c1e9:0x8916c206f41acab2!8m2!3d47.474322!4d19.0719349!16s%2Fg%2F11c6rh13ds?entry=ttu"
                            aria-label="map"
                        >
                            <PinMapFill size={32} />
                        </a>
                    </div>
                </Text>
            )}
        />
    </div>
);

export default Contact;
