exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".RbCJ7F-bLfhUPOMYmcNfm {\n    padding-top: 32px;\n    padding-left: 80px;\n    width: 100%;\n}\n\n@media (max-width: 768px) {\n    .RbCJ7F-bLfhUPOMYmcNfm {\n        padding-top: 0;\n        padding-left: 15px;\n    }\n}", ""]);

// exports
exports.locals = {
	"root": "RbCJ7F-bLfhUPOMYmcNfm"
};