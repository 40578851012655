// eslint-disable-next-line import/prefer-default-export
export const routes = {
    bkkStory: '/story/bkk',
    szombathelyStory: '/story/szombathely',
    ktiStory: '/story/kti',
    impulseStory: '/story/impulse',
    driveProduct: '/product/drive',
    dispatchProduct: '/product/dispatch',
    replayProduct: '/product/replay',
    rideProduct: '/product/ride',
    main: '/',
};
