exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PPxVbwJ4qz0BLfYD7RbqW {\n    font-size: 48px;\n    font-weight: 600;\n    letter-spacing: 0.2px;\n    text-align: left;\n}\n\n@media (max-width: 992px) {\n    .PPxVbwJ4qz0BLfYD7RbqW {\n        white-space: normal;\n    }\n}\n\n@media (max-width: 576px) {\n    .PPxVbwJ4qz0BLfYD7RbqW {\n        font-size: 36px;\n    }\n}", ""]);

// exports
exports.locals = {
	"root": "PPxVbwJ4qz0BLfYD7RbqW"
};