exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1sIO6mJSIIOxgj_3GTKOMf {\n    font-size: 18px;\n    line-height: 1.6;\n    text-align: justify;\n    padding-top: 20px;\n}", ""]);

// exports
exports.locals = {
	"root": "_1sIO6mJSIIOxgj_3GTKOMf"
};