import React from 'react';
import DetailsSection from './DetailsSection';
import Text from './Text';

const DetailsText: React.FC = ({ children }) => (
    <DetailsSection>
        <Text>
            {children}
        </Text>
    </DetailsSection>
);

export default DetailsText;
