import type { PropsWithChildren } from 'react';
import React from 'react';
import * as styles from '../css/category.css';
import { classNames } from '../utils';

interface Props {
    children: React.ReactNode;
    className?: string;
    centered?: boolean;
}

const Category = ({ children, className, centered }: PropsWithChildren<Props>) => (
    <div className={classNames(styles.root, className, centered && styles.alignTextCenter)}>
        {children}
    </div>
);

export default Category;
