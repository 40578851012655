exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1uZm2KBjZtltH85n_PiVa6 {\n    font-size: 32px;\n    font-weight: 600;\n    line-height: 40.22px;\n    letter-spacing: -0.37px;\n    color: #666666;\n}\n\n._315Cdkjk0vKIjVCv2ago9q {\n    display: flex;\n}\n\n._3ApHxWUzrwfZ7bHdES96BW {\n    display: inline-flex;\n    align-items: center;\n    margin-left: -60px;\n}\n\n._1-EmKMHauo3KJTdYZxdf2D:hover, ._1-EmKMHauo3KJTdYZxdf2D:active, ._1-EmKMHauo3KJTdYZxdf2D:focus {\n    background-color: transparent;\n    outline: transparent;\n}\n\n._3bYLy8GrG16OOx7eopj9a4 {\n    font-size: 48px;\n    margin-top: 0;\n}\n\n._2QLvq4fJKdH58CZSqWr4Mk {\n    text-align: left;\n    padding-top: 50px;\n}\n\n@media (max-width: 992px) {\n    ._3bYLy8GrG16OOx7eopj9a4 {\n        font-size: 28px;\n    }\n}\n\n@media (max-width: 576px) {\n    ._32Ccd5SX7fCFau9s-196QJ {\n        top: -20px;\n    }\n}\n", ""]);

// exports
exports.locals = {
	"subtitle": "_1uZm2KBjZtltH85n_PiVa6",
	"icon": "_315Cdkjk0vKIjVCv2ago9q",
	"title-with-exit-icon-container": "_3ApHxWUzrwfZ7bHdES96BW",
	"titleWithExitIconContainer": "_3ApHxWUzrwfZ7bHdES96BW",
	"btn-close": "_1-EmKMHauo3KJTdYZxdf2D",
	"btnClose": "_1-EmKMHauo3KJTdYZxdf2D",
	"title": "_3bYLy8GrG16OOx7eopj9a4",
	"root": "_2QLvq4fJKdH58CZSqWr4Mk",
	"body": "_32Ccd5SX7fCFau9s-196QJ"
};