import * as React from 'react';
import * as styles from '../css/powering.css';
import PoweringImageContainer from '../components/PoweringImageContainer';
import PoweringImage from '../components/PoweringImage';
import Category from '../components/Category';

const Powering = () => (
    <div className={styles.container}>
        <Category centered>Powering</Category>
        <PoweringImageContainer isGrayscale>
            <PoweringImage className={styles.bkkLogo} />
            <PoweringImage className={styles.szombathelyLogo} />
            <PoweringImage className={styles.paksLogo} />
            <PoweringImage className={styles.szekesfehervarLogo} />
            <PoweringImage className={styles.vbuszLogo} />
            <PoweringImage className={styles.ktiLogo} />
            <PoweringImage className={styles.mmkmLogo} />
            <PoweringImage className={styles.otpLogo} />
            <PoweringImage className={styles.volanbuszLogo} />
            <PoweringImage className={styles.stadtnaviLogo} />
        </PoweringImageContainer>
    </div>
);

export default Powering;
