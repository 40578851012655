import React from 'react';
import ProductCard from '../components/ProductCard';
import headset from '../assets/images/products/headset.svg';
import { routes } from '../routes';

const DispatchCard = () => (
    <ProductCard
        cardTitle="dispatch"
        navigateTo={routes.dispatchProduct}
        color="blue"
        icon={headset}
    >
        Web based fleet management interface to supervise and manage vehicle and driver activity. Intervene as
        needed with the many available dispatch functions to minimize disruptions.
    </ProductCard>
);

export default DispatchCard;
