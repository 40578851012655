import React from 'react';
import { ScrollRestoration } from 'react-router-dom';
import HeaderSection from './HeaderSection';
import Products from './Products';
import Contact from './Contact';
import Powering from './Powering';
import * as styles from '../css/styles.css';
import About from './About';
import BkkSection from './BkkSection';
import KtiSection from './KtiSection';
import SzombathelySection from './SzombathelySection';
import { sectionIds } from './utils';
import ImpulseSection from './ImpulseSection';

const MainPage = () => {
    const { productsId, storiesId, contactId, aboutId } = sectionIds;

    return (
        <div className={styles.globalContainer}>
            <HeaderSection />
            <Products id={productsId} />
            <BkkSection id={storiesId} />
            <SzombathelySection />
            <KtiSection />
            <ImpulseSection />
            <About id={aboutId} />
            <Powering />
            <Contact id={contactId} />
            <ScrollRestoration />
        </div>
    );
};

export default MainPage;
