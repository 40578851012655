exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1TVT5wxe2m9hasmk5SzokC, ._1TVT5wxe2m9hasmk5SzokC:visited {\n    font-weight: 700;\n    color: #aaa;\n    text-decoration: underline;\n}\n\n._1TVT5wxe2m9hasmk5SzokC:hover {\n    color: #ccc\n}", ""]);

// exports
exports.locals = {
	"modal-link": "_1TVT5wxe2m9hasmk5SzokC",
	"modalLink": "_1TVT5wxe2m9hasmk5SzokC"
};