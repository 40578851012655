exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1Q7hBzUHlnmuHsc0UbTm0V {\n    height: 20%;\n    display: flex;\n    align-items: flex-start;\n}\n", ""]);

// exports
exports.locals = {
	"links-container": "_1Q7hBzUHlnmuHsc0UbTm0V",
	"linksContainer": "_1Q7hBzUHlnmuHsc0UbTm0V"
};