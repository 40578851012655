import type { HTMLProps } from 'react';
import React from 'react';
import * as styles from '../css/products.css';
import DispatchCard from './DispatchCard';
import DriveCard from './DriveCard';
import ReplayCard from './ReplayCard';
import RideCard from './RideCard';
import TextWithImageSection from '../components/TextWithImageSection';
import Title from '../components/Title';
import Category from '../components/Category';
import Text from '../components/Text';

const Products = (props: HTMLProps<HTMLDivElement>) => (
    <div {...props}>
        <TextWithImageSection
            text={(
                <>
                    <Category>Products</Category>
                    <Title>What we provide</Title>
                    <Text>
                        <p>
                            realCity built a modular, cloud-based traffic management and passenger information system.
                            It can be an end-to-end solution or customized based on critical areas within the IT
                            infrastructure serving passengers, dispatchers, and drivers.
                        </p>
                        <p>
                            We use the software-as-a-service (SaaS) model to provide a quick, readily usable, modern
                            experience for the operators and organizers without needing initial investment.
                        </p>
                        <p>
                            Our four product group covers the following:
                        </p>
                    </Text>
                </>
            )}
            image={(
                <iframe
                    className={styles.video}
                    src="https://www.youtube.com/embed/5IacfFRldxw"
                    title="realCity | Portfolio video 2023"
                    allowFullScreen
                />
            )}
            alignment="image-left"
        />
        <div className={styles.cardContainer}>
            <div className={styles.commonCardContent}>
                <DriveCard />
                <DispatchCard />
            </div>
            <div className={styles.commonCardContent}>
                <RideCard />
                <ReplayCard />
            </div>
        </div>
    </div>
);

export default Products;
