import React from 'react';
import * as styles from '../css/details-category.css';
import Category from './Category';

const DetailsCategory: React.FC = ({ children }) => (
    <Category className={styles.root}>
        {children}
    </Category>
);

export default DetailsCategory;
