import type { PropsWithChildren } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';
import * as styles from '../css/details-link.css';
import { classNames } from '../utils';

export interface DetailsLinkProps {
    navigateTo: string;
    className?: string;
}

const DetailsLink = ({ children, navigateTo, className }: PropsWithChildren<DetailsLinkProps>) => (
    <Link to={navigateTo} className={classNames(styles.root, className)}>
        {children}
    </Link>
);

export default DetailsLink;
