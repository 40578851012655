import * as React from 'react';
import DetailsHeaderText from '../components/DetailsHeaderText';
import * as styles from '../css/szombathely-section.css';
import SmartCarousel from '../components/SmartCarousel';
import szombathelyImage1 from '../assets/images/szombathely/szombathely-1.png';
import dispatchImage1 from '../assets/images/dispatch/dispatch_1.png';
import dispatchImage2 from '../assets/images/dispatch/dispatch_2.png';
import dispatchImage3 from '../assets/images/dispatch/dispatch_3.png';
import driveImage1 from '../assets/images/drive/drive_1.jpg';
import driveImage2 from '../assets/images/drive/drive_2.png';
import replayImage1 from '../assets/images/szombathely/szombathely_replay_1.png';
import replayImage2 from '../assets/images/szombathely/szombathely_replay_2.png';
import szombathelyTransparent1 from '../assets/images/szombathely/szombathely-transparent1.png';
import appStoreImage from '../assets/images/app-store-badge.png';
import googlePlayImage from '../assets/images/google-play-badge.png';
import webSiteImage from '../assets/images/szombathely/szombathely-badge.png';
import DetailsText from '../components/DetailsText';
import DownloadLink from '../components/DownloadLink';
import DownloadLinks from '../components/DownloadLinks';

const SzombathelyDetailsContent = () => (
    <>
        <DetailsHeaderText
            category="Stories"
            title="Blaguss Agora"
        />
        <DetailsText>
            <p>
                At the end of 2017, realCity started to develop its own onboard unit software responding to market
                demands, while it was searching for and
                testing Android devices and tablets that allow the installation of onboard systems easily and quickly.
            </p>
            <p>
                The solution had its debut at the IT-Transport 2018 Traffic Information Exhibition in Germany.
            </p>
            <p>
                In the fall of 2021, our team began to customize and install our products for Blaguss Agora, Transport
                Company in Szombathely following an order with a very short deadline. We delivered a full-stack public
                transport information system: stock Android tablet serving as an on-board unit, web and mobile passenger
                information solutions, a dispatcher interface and a fact data management system, fully integrated with
                on-board passenger information and ticket management systems.
            </p>
            <p>
                Thanks to our software as a service (SaaS) model that leverages the cloud, and the quickly deployable on
                board devices, we managed to set up the complete solution for the entire fleet of the customer,
                consisting of 33 vehicles, on time by the end of December 2021.
            </p>
            <p>
                As a result of the quick start-up, Blaguss Agora was able to start operating as a new and modern service
                in Szombathely. The web and mobile journey planner solution quickly became the favorite of residents.
                The web and mobile information platforms are used by 14 000 unique active users per month.
            </p>
            <p>
                Dispatchers can easily monitor the vehicles using our solution and thanks to the recorded fact data,
                passenger complaints and delays can be
                easily investigated.
            </p>
            <p>
                <a className={styles.modalLink} href="https://www.youtube.com/watch?v=_u5SQjkHoNo&feature=youtu.be">Here</a>
                {' '}
                is a short video about our Szombathely setup.
            </p>
        </DetailsText>
        <SmartCarousel
            images={
                [
                    szombathelyImage1,
                    dispatchImage1,
                    dispatchImage2,
                    dispatchImage3,
                    driveImage1,
                    driveImage2,
                    replayImage1,
                    replayImage2,
                    szombathelyTransparent1,
                ]
            }
        />
        <DownloadLinks>
            <DownloadLink href="https://itunes.apple.com/hu/app/szombathely-utas/id1596723705" imageSrc={appStoreImage} />
            <DownloadLink href="https://play.google.com/store/apps/details?id=io.realcity.mobile.savaria" imageSrc={googlePlayImage} />
            <DownloadLink href="https://szombathely.utas.hu" imageSrc={webSiteImage} />
        </DownloadLinks>
    </>
);
export default SzombathelyDetailsContent;
