exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "body {\n    font-family: \"Source Sans Pro\", sans-serif;\n    line-height: normal;\n}\n\na, a:hover {\n    color: #333;\n}\n\n._8V4dSrrRu8oMQGU2tiqQW {\n    width: 100%;\n    height: 100%;\n    overflow-x: hidden;\n}", ""]);

// exports
exports.locals = {
	"globalContainer": "_8V4dSrrRu8oMQGU2tiqQW"
};