import React from 'react';
import TextWithImageSection from '../components/TextWithImageSection';
import ImageWithPath from '../components/ImageWithPath';
import { routes } from '../routes';
import impulseRefImage from '../assets/images/impulse/impulse-ref.png';
import Category from '../components/Category';
import Title from '../components/Title';
import Text from '../components/Text';
import SectionDetailsLink from '../components/SectionDetailsLink';

const ImpulseSection = () => (
    <TextWithImageSection
        text={(
            <>
                <Category>Stories</Category>
                <Title>Supporting Electric Bus Operations - IMPULSE project</Title>
                <Text>
                    <p>
                        realCity is part of an EIT Urban Mobility Innovation Call in 2024 that focuses on the future of
                        sustainable public transportation. During this project, we are enhancing our software stack with
                        features designed to support the operation of electric buses, optimizing fleet efficiency and
                        performance. This initiative also gives us the chance to test these new features in real-world
                        settings during two pilot programs in European cities. By participating in this project,
                        realCity aims to drive innovation and promote greener urban mobility solutions.
                    </p>
                </Text>
                <SectionDetailsLink navigateTo={routes.impulseStory}>
                    Details
                </SectionDetailsLink>
            </>
        )}
        image={(
            <ImageWithPath
                variant="left"
                image={impulseRefImage}
            />
        )}
        alignment="image-left"
    />
);

export default ImpulseSection;
