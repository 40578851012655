import React from 'react';
import DetailsHeaderText from '../components/DetailsHeaderText';
import SmartCarousel from '../components/SmartCarousel';
import dispatchImage1 from '../assets/images/dispatch/dispatch_1.png';
import dispatchImage2 from '../assets/images/dispatch/dispatch_2.png';
import dispatchImage3 from '../assets/images/dispatch/dispatch_3.png';
import dispatchImage4 from '../assets/images/dispatch/dispatch_4.png';
import DetailsText from '../components/DetailsText';
import DetailsList from '../components/DetailsList';

const DispatchDetailsContent = () => (
    <>
        <DetailsHeaderText
            category="Products"
            title="realCity | dispatch"
            subtitle="Web-based fleet management interface to
            supervise and manage vehicle and driver activity
            using data from a solution like realCity | drive."
        />
        <DetailsText>
            <DetailsList>
                <li>Track vehicles on the high quality vector map</li>
                <li>Search for vehicles, stops and routes</li>
                <li>Monitor the performance of the timetable, prevent delays</li>
                <li>Block, vehicle and driver details</li>
                <li>
                    Ability to intervene in case of disruptions by applying
                    real-time trip modifications
                </li>
                <li>
                    Integrated messaging between dispatchers and
                    drivers
                </li>
                <li>Detailed list view of vehicles</li>
                <li>Automatic notifications for detected issues</li>
            </DetailsList>
        </DetailsText>
        <SmartCarousel
            images={[
                dispatchImage1,
                dispatchImage2,
                dispatchImage3,
                dispatchImage4,
            ]}
        />
    </>
);

export default DispatchDetailsContent;
