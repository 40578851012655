/* eslint-disable max-len */
import * as React from 'react';
import ImageWithPath from '../components/ImageWithPath';
import TextWithImageSection from '../components/TextWithImageSection';
import szombathelyRefImage from '../assets/images/szombathely/szombathely-ref.png';
import { routes } from '../routes';
import Title from '../components/Title';
import Category from '../components/Category';
import Text from '../components/Text';
import SectionDetailsLink from '../components/SectionDetailsLink';

const SzombathelySection: React.FC = () => (
    <TextWithImageSection
        text={
            (
                <>
                    <Category>Stories</Category>
                    <Title>Szombathely</Title>
                    <Text>
                        <p>
                            realCity won the procurement of public transport information system for the city of Szombathely, where we have deployed our full stack of services: from the flexible onboard units through the dispatch interfaces to the passenger information mobile apps.
                            <br />
                            While the deadline was incredibly short, we had managed to deploy our solution package within weeks during the winter holiday season.
                        </p>
                    </Text>
                    <SectionDetailsLink navigateTo={routes.szombathelyStory}>
                        Details
                    </SectionDetailsLink>
                </>
            )
        }
        image={(
            <ImageWithPath
                variant="left"
                image={szombathelyRefImage}
            />
        )}
        alignment="image-left"
    />
);

export default SzombathelySection;
